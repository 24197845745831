// 基础性全局变量，如baseUrl

const oldBase = "http://120.76.239.116";
const env = {
  // 公共属性
  common: {
    baseUrl: oldBase + '/api',
    // baseUrl: 'http://127.0.0.1:8080/api', // 此行不要上传
    filServiceUrl: oldBase + '/fileApi'
  },

  // 开发环境变量
  development: {
    ENV: 'development',
    baseUrl: 'http://127.0.0.1:8080/api',
    filServiceUrl: 'http://127.0.0.1:8090/fileApi',
    // baseUrl: 'http://120.76.239.116/api', // 这两行修改用于本地调试
    // filServiceUrl: oldBase + '/fileApi'
  },

  // 正式环境变量
  production: {
    ENV: 'production',
    baseUrl: 'http://120.76.239.116/api',
    filServiceUrl: oldBase + '/fileApi'
  }
}

const environment = {
  ...env.common,
  ...env[process.env.NODE_ENV]
}

// 若当前网站使用https访问，则接口也用https
// 若当前网站使用域名访问，则接口也用域名
const temp = environment;
const origin = window.location.origin;
if(origin.indexOf("localhost") == -1) {   // 本地调试不需要更换接口地址
  for(let key in temp) {
    if(temp[key].indexOf(oldBase) == 0) {
      temp[key] = origin + temp[key].substring(oldBase.length);
    }
  }
}
export default environment;
