<!-- 顶部 -->
<template>
<div class="root-241102">
    <div class="left"></div>
    <div class="title">{{ $getLang('匿名交流平台') }}</div>
    <div class="right">
        <div class="lang-container">
            <!-- <div class="label">语言</div> -->
            <el-select v-model="language" placeholder="请选择语言" class="select">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
            </el-select>
        </div>
        <div class="user-container" title="点击查看用户信息" @click="showUserInfo">
            <img :src="avatarUrl" alt="" class="img"/>
            <div class="username">{{ userName }}</div>
        </div>
    </div>

    <el-dialog
        v-model="userInfoDialogVisible"
        :title="this.$getLang('用户信息')"
        :close-on-click-modal="false"
        @close="onCloseUserInfoDialog"
    >
        <div id="myId202412291">
            <div class="row">
                <div class="label" :class="{minWidth20250110: isEnglish}">{{ this.$getLang('用户标识') }}</div>
                <div class="value">{{ dialogData.uuid }}</div>
            </div>
            <div class="row">
                <div class="label" :class="{minWidth20250110: isEnglish}">{{ this.$getLang("用户名称") }}</div>
                <template v-if="!isEditUsername">
                    <div class="value">{{ dialogData.username }}</div>
                    <div class="edit">
                        <el-button
                            text
                            type="primary"
                            @click="onClickEditUsername"
                        >
                            {{ this.$getLang("编辑") }}
                        </el-button>
                    </div>
                </template>
                <template v-else>
                    <el-input v-model="dialogData.usernameModel" />
                    <div class="edit">
                        <el-button
                            text
                            type="primary"
                            @click="onClickSaveUsername"
                        >
                            {{ this.$getLang("保存") }}
                        </el-button>
                    </div>
                </template>
            </div>
            <div class="row">
                <div class="label" :class="{minWidth20250110: isEnglish}">{{ this.$getLang("用户头像") }}</div>
                <template v-if="!isEditAvatar">
                    <div class="value">{{ dialogData.avatar }}</div>
                    <div class="edit">
                        <el-button
                            text
                            type="primary"
                            @click="onClickEditAvatar"
                        >
                        {{ this.$getLang("编辑") }}
                        </el-button>
                    </div>
                </template>
                <template v-else>
                    <el-input v-model="dialogData.avatarModel" />
                    <div class="edit">
                        <el-button
                            text
                            type="primary"
                            @click="onClickSaveAvatar"
                        >
                            {{ this.$getLang("保存") }}
                        </el-button>
                    </div>
                </template>
            </div>
        </div>
    </el-dialog>
    <div v-if="false">
        <el-message></el-message>
    </div>
</div>
</template>

<script>
import {
    DEFAULT_USER_NAME,
    NAME_FOR_NO_NAME
} from "@/static/staticValue"
import {
    refreshChatList
} from "@/static/eventName"
import{
    ElSelect,
    ElOption,
    ElDialog,
    ElButton,
    ElInput,
    ElMessage
} from 'element-plus'
import {
    saveUserInfo
} from "@/api/user"
import {
    getUserInfo
} from "@/api/registerLogin"
import {
    langOption
} from "./data"
export default {
    name: "TopContainer",
    components: {
        ElSelect,
        ElOption,
        ElDialog,
        ElButton,
        ElInput,
        ElMessage
    },
    created() {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.userName = NAME_FOR_NO_NAME;
        this.avatarUrl = userInfo.avatar;
        if(userInfo.username != DEFAULT_USER_NAME) {
            this.userName = userInfo.username;
        } else {
            this.userName = NAME_FOR_NO_NAME;
        }
        this.initUserDialog();
    },
    data() {
        return{
            language: 'zh-CN',
            options: langOption,    // 语言选项
            avatarUrl: "https://img1.baidu.com/it/u=3721293528,1391032071&fm=253&fmt=auto?w=171&h=171",      // 用户头像图片地址
            userName: "",
            userInfoDialogVisible: false,    // 是否显示用户信息弹窗
            dialogData: {       // 用户信息弹窗数据
                uuid: '',
                username: '',
                avatar: '',
                usernameModel: '',
                avatarModel: ''
            },
            isEditUsername: false,      // 是否正在编辑用户名称
            isEditAvatar: false,        // 是否正在编辑用户头像
            isEnglish: false,           // 当前语言是否是英文
            hasChangeUserInfo: false,   // 是否成功修改过用户的某个信息
        }
    },
    methods: {
        // 初始化或者重新设置用户信息弹窗
        initUserDialog() {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            for(let key in userInfo) {
            if(key in this.dialogData) {
                this.dialogData[key] = userInfo[key];
            }
            this.dialogData.usernameModel = this.dialogData.username;
            this.dialogData.avatarModel = this.dialogData.avatar;
            this.isEditUsername = false;
        }
        },
        showUserInfo() {
            if(this.userInfoDialogVisible == false) {
                this.hasChangeUserInfo = false;
            }
            // console.log("查看用户信息");
            this.userInfoDialogVisible = true;
            
            this.initUserDialog();
            // console.log(this.dialogData);
        },
        onClickEditUsername() {
            // console.log("点击编辑");
            this.isEditUsername = true;
        },
        onClickEditAvatar() {
            // console.log("点击编辑");
            this.isEditAvatar = true;
        },
        onClickSaveUsername() {
            // console.log("点击保存", this.dialogData.usernameModel);
            saveUserInfo({
                username: this.dialogData.usernameModel
            }).then(res => {
                // console.log("res", res);
                if(res.code === 1000) {
                    this.hasChangeUserInfo = true;
                    getUserInfo().then(res => {
                        localStorage.setItem("userInfo", JSON.stringify(res.result));
                        const userInfo = res.result;
                        if(userInfo.username != DEFAULT_USER_NAME) {
                            this.userName = userInfo.username;
                        } else {
                            this.userName = NAME_FOR_NO_NAME;
                        }

                        this.initUserDialog();
                    })
                    ElMessage({
                        type: 'success',
                        message: this.$getLang('用户名称修改成功')
                    })
                    this.isEditUsername = false;
                }
            })
        },
        onClickSaveAvatar() {
            // console.log("点击保存", this.dialogData.avatarModel);
            saveUserInfo({
                avatar: this.dialogData.avatarModel
            }).then(res => {
                // console.log("res", res);
                if(res.code === 1000) {
                    this.hasChangeUserInfo = true;
                    getUserInfo().then(res => {
                        localStorage.setItem("userInfo", JSON.stringify(res.result));
                        const userInfo = res.result;
                        if(userInfo.username != DEFAULT_USER_NAME) {
                            this.avatarUrl = userInfo.avatar;
                        }

                        this.initUserDialog();
                    })
                    ElMessage({
                        type: 'success',
                        message: this.$getLang('用户头像修改成功')
                    })
                    this.isEditAvatar = false;
                }
            })
        },
        onCloseUserInfoDialog() {
            if(this.hasChangeUserInfo && this.$route.path == "/publicChat") {
                console.log("此时应该重新请求数据");
                this.$eventBus.emit(refreshChatList);
            }
        }
    },
    watch: {
        language(newValue) {
            this.$store.commit("setLanguage", newValue);
            document.title = this.$getLang("匿名交流平台");
            this.isEnglish = newValue =='en';
        }
    }
}
</script>

<style scoped>
.root-241102 {
    background: #efebeb52;
    border-bottom: none;
    padding: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .left {
        flex-grow: 1;
    }

    .right {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 13px;

        .lang-container {
            display: flex;
            align-items: center;
            .select {
                min-width: 100px;
            }
        }
        .user-container {
            border-radius: 8px;
            right: 145px;
            display: flex;
            align-items: center;
            margin-right: 5vw;
            .img {
                width: 30px;
                height: 30px;
                display: bolck;
                vertical-align: bottom;
            }
            .username {
                margin-left: 10px;
                width: fit-content;
                max-width: 140px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        .user-container:hover {
            cursor: pointer;
        }
    }
}
</style>
<style>
#myId202412291 {
    display: flex;
    flex-direction: column;
    gap: 25px;
    .row {
        display: flex;
        align-items: center;
        .label {
            min-width: 77px;
        }
        .value {
            flex-grow: 1;
            word-break: break-all;
        }
    }
}
.minWidth20250110 {
    min-width: 90px !important;
}
</style>