// 本文件存放一些常量

// 默认的用户名
export const DEFAULT_USER_NAME = "";

// 默认的用户头像
export const DEFAULT_USER_AVATAR = "https://img1.baidu.com/it/u=3721293528,1391032071&fm=253&fmt=auto?w=171&h=171";

// 当用户名称为空字符串时，顶部所显示的用户名称
export const NAME_FOR_NO_NAME = "你还没有设置名称";
