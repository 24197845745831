// 本文件存放的是多语言功能所用到的字符串

// 每个字符串有相同格式的key
export const lang = {
    "cccacbdcf5017f33d5702b6b7ae73f80fe5a0cac3cb5922945302e98f6bb0732": {
        "zh-CN": "匿名交流平台",
        "en": "Anonymous communication platform"
    },
    "d1648bacbc1630a60ebc0091fa7a4cb7d831c4696a80f302dcacfc59838895a0": {
        "zh-CN": "英语",
        "en": "English"
    },
    "3a65cac37c968725f56d3a4df14e830b7323ead9d20740e2ef3a3845214bd14d": {
        "zh-CN": "公共聊天",
        "en": "Let's chat"
    },
    "fcea0bb8ad5da97d2a5099235db42d980488dd8b1fe88048edf95602b7a5f0f3": {
        "zh-CN": "说点什么",
        "en": "Say something"
    },
    "7e56509428cb9bbb787acda135941100ec665cfb7fed63ccf12d157627715279": {
        "zh-CN": "发表",
        "en": "Submit"
    },
    "dbb891595578490e9471636ccd386201084f28a42d81c7ee083112d66061f302": {
        "zh-CN": "匿名用户",
        "en": "Anonymous user"
    },
    "fb579451f80a2a7b2f2dfaa3c9791a29c7780a3d77cf37c2e5551e103f6bb271": {
        "zh-CN": "如果您认可我们的产品并想支持我们，欢迎微信扫码赞助！",
        "en": "If you like our products and want to support us, please scan the WeChat QR code to donate!"
    },
    "cb4ba40bf823511da493b4e7535ccf2e9fcba7051f945ff9793e28da79bc81bf": {
        "zh-CN": "使用说明",
        "en": "Instructions"
    },
    "5b71c2e6ccbc4ee9116425242b699cee70de91606a4ab1c145304d0de4e1776d": {
        "zh-CN": "这是一款完全不需要身份认证的聊天软件，切实保障您的信息安全",
        "en": "This is a chat software that requires no identity verification, ensuring the security of your information"
    },
    "fcd9b44e6d424f3eddf5f14227ca5de0422b6eccf91add7a5322260c7b35e85b": {
        "zh-CN": "版权所有，侵权必究",
        "en": "All rights reserved. Infringement will be prosecuted"
    },
    "373febf03109b098fe4e4f34732c9744e3252953b661333e53a2d29f39efab93": {
        "zh-CN": "开发工具",
        "en": "Dev Tool"
    },
    "426c4548f68a553ffe4567baab24ee95c311ef0a5e9a5e83f8905bb14a7afc22": {
        "zh-CN": "用户信息",
        "en": "User Information"
    },
    "cf434320708ab64db73713731d45acdcb78b3d739b710915299444940b1b99c9": {
        "zh-CN": "用户标识",
        "en": "User ID"
    },
    "6bb5d777aca5b09411552d7a2de756f51704d5744ad7a924b207ece309f1e43e": {
        "zh-CN": "用户名称",
        "en": "User Name"
    },
    "8501bb8e47c3bd67e7d1151b9d1935c36c276af51c1ef2521ab209124df5b727": {
        "zh-CN": "用户头像",
        "en": "User Avatar"
    },
    "051836569928a9f91145a70e00b212b62a06c21a97d2d828cb75acd3fbe851b9": {
        "zh-CN": "编辑",
        "en": "edit"
    },
    "a3030bf8f16dc63c6b277564c68f78a3be5abe5f2907c51826dee7184ff793fd": {
        "zh-CN": "保存",
        "en": "save"
    },
    "6ca588f52323df7ca0ed79c3bc6e085197ae3f885dba51b613775c3ec93a5e37": {
        "zh-CN": "用户名称修改成功",
        "en": "Username successfully updated"
    },
    "443d43dc23b74f58bde9261d108770003ae3e3f8d24812d2743d628ca0a40b90": {
        "zh-CN": "用户头像修改成功",
        "en": "Avatar successfully updated"
    }

    
    
}

// 保存字符串对应的key
export const keyObj = {
    "用户头像修改成功": "443d43dc23b74f58bde9261d108770003ae3e3f8d24812d2743d628ca0a40b90",
    "用户名称修改成功": "6ca588f52323df7ca0ed79c3bc6e085197ae3f885dba51b613775c3ec93a5e37",
    "保存": "a3030bf8f16dc63c6b277564c68f78a3be5abe5f2907c51826dee7184ff793fd",
    "编辑": "051836569928a9f91145a70e00b212b62a06c21a97d2d828cb75acd3fbe851b9",
    "匿名交流平台": "cccacbdcf5017f33d5702b6b7ae73f80fe5a0cac3cb5922945302e98f6bb0732",
    "英语": "d1648bacbc1630a60ebc0091fa7a4cb7d831c4696a80f302dcacfc59838895a0",
    "公共聊天": "3a65cac37c968725f56d3a4df14e830b7323ead9d20740e2ef3a3845214bd14d",
    "说点什么": "fcea0bb8ad5da97d2a5099235db42d980488dd8b1fe88048edf95602b7a5f0f3",
    "发表": "7e56509428cb9bbb787acda135941100ec665cfb7fed63ccf12d157627715279",
    "匿名用户": "dbb891595578490e9471636ccd386201084f28a42d81c7ee083112d66061f302",
    "如果您认可我们的产品并想支持我们，欢迎微信扫码赞助！": "fb579451f80a2a7b2f2dfaa3c9791a29c7780a3d77cf37c2e5551e103f6bb271",
    "使用说明": "cb4ba40bf823511da493b4e7535ccf2e9fcba7051f945ff9793e28da79bc81bf",
    "这是一款完全不需要身份认证的聊天软件，切实保障您的信息安全": "5b71c2e6ccbc4ee9116425242b699cee70de91606a4ab1c145304d0de4e1776d",
    "版权所有，侵权必究": "fcd9b44e6d424f3eddf5f14227ca5de0422b6eccf91add7a5322260c7b35e85b",
    "开发工具": "373febf03109b098fe4e4f34732c9744e3252953b661333e53a2d29f39efab93",
    "用户信息": "426c4548f68a553ffe4567baab24ee95c311ef0a5e9a5e83f8905bb14a7afc22",
    "用户标识": "cf434320708ab64db73713731d45acdcb78b3d739b710915299444940b1b99c9",
    "用户名称": "6bb5d777aca5b09411552d7a2de756f51704d5744ad7a924b207ece309f1e43e",
    "用户头像": "8501bb8e47c3bd67e7d1151b9d1935c36c276af51c1ef2521ab209124df5b727"
}