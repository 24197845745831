// 用户后端接口
import { request } from "@/utils/request";

// 保存用户信息
export function saveUserInfo(data) {
    return request("/user/saveUserInfo", {
        method: "POST",
        body: JSON.stringify(data)
    })
}

// 用户列表
export function getUserList(data) {
    return request("/user/getUserList", {
        method: "POST",
        body: JSON.stringify(data)
    })
}