import environment from "@/config/env"

export function request(url, options) {
  let accessToken = localStorage.getItem("accessToken");
  
  // 选项的默认配置
  const defaults = {
    headers: {
      'Content-Type': 'application/json',
      "accessToken": accessToken? accessToken : ''
    }
  }

  // 合并选项
  const opts = {
    ...defaults,
    ...options
  }

  // 若body里是FormData类型，则不设置Content-Type字段
  let body = options.body;
  if(body && typeof body == 'object' && body.constructor.name == 'FormData') {
    console.log("是formdata");
    delete opts.headers["Content-Type"];
  }

  // 拼接url
  let baseUrl = environment.baseUrl;
  if(options.baseUrl) {   // 设置了该属性，说明该请求需要使用不同的请求地址
    baseUrl = environment[options.baseUrl]
  }
  const fullUrl = baseUrl + url;
  // console.log("fullUrl", fullUrl);

  return fetch(fullUrl, opts).then(response => {
    // console.log('response', response);

    return response.json();
  }).catch(error => {
    console.error(error);
  })

}